import { lazy } from "react";

const EnterDetails = lazy(() => import("../../views/EnterDetails"));
const VerifyToken = lazy(() => import("../../views/VerifyToken"));
const AddAccount = lazy(() => import("../../views/AddAccount"));
const Confirmation = lazy(() => import("../../views/Confirmation"));

const CallUs = lazy(() => import("../../views/CallUs"));
const SessionTimeout = lazy(() => import("../../views/SessionTimeout"));
const SystemUnavailable = lazy(() => import("../../views/SystemUnavailable"));

export enum RefundRoutes {
  EnterDetails = "enter-details",
  VerifyToken = "verify-token",
  AddAccount = "add-account",
  Confirmation = "confirmation",
}

export enum RefundPageNames {
  EnterDetails = "Identify your reimbursement",
  VerifyToken = "Verify it's you",
  AddAccount = "Enter your bank details",
  Confirmation = "Confirmation",
}

export enum RefundPageHeadings {
  EnterDetails = "Let's get some information",
  VerifyToken = "Let's verify it's you",
  AddAccount = "Enter your bank details",
}

export enum UtilityRoutes {
  SessionTimeout = "408",
  SystemUnavailable = "error",
  CallUs = "call",
}

export enum UtilityPageNames {
  SessionTimeout = "Session timed out",
  SystemUnavailable = "System unavailable",
  CallUs = "Call us",
}

export enum UtilityPageHeadings {
  SessionTimeout = "Uh oh!",
  SystemUnavailable = "Uh oh!",
  CallUs = "Uh oh!",
  Default = "Uh oh!",
}

const getRouteUrl = (route: RefundRoutes | UtilityRoutes) => `${process.env.PUBLIC_URL}/${route}`;
const getRoutePath = (route: RefundRoutes) => `/member/refund/${route}`;

export const ENTER_DETAILS_PAGE_URL = getRouteUrl(RefundRoutes.EnterDetails);
export const VERIFY_TOKEN_PAGE_URL = getRouteUrl(RefundRoutes.VerifyToken);
export const ADD_ACCOUNT_PAGE_URL = getRouteUrl(RefundRoutes.AddAccount);
export const CONFIRMATION_PAGE_URL = getRouteUrl(RefundRoutes.Confirmation);
export const UTILITY_CALL_US_PAGE_URL = getRouteUrl(UtilityRoutes.CallUs);
export const UTILITY_SESSION_TIMEOUT_PAGE_URL = getRouteUrl(UtilityRoutes.SessionTimeout);
export const UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL = getRouteUrl(UtilityRoutes.SystemUnavailable);

export const ENTER_DETAILS_PATH = getRoutePath(RefundRoutes.EnterDetails);
export const VERIFY_TOKEN_PATH = getRoutePath(RefundRoutes.VerifyToken);
export const ADD_ACCOUNT_PATH = getRoutePath(RefundRoutes.AddAccount);
export const CONFIRMATION_PATH = getRoutePath(RefundRoutes.Confirmation);

export interface RouteInformation {
  key: string;
  path: string;
  name: string;
  element: JSX.Element;
  heading?: string;
}

export const refundRoutesInfo: Record<RefundRoutes, RouteInformation> = {
  [RefundRoutes.EnterDetails]: {
    key: RefundRoutes.EnterDetails,
    path: ENTER_DETAILS_PAGE_URL,
    name: RefundPageNames.EnterDetails,
    heading: RefundPageHeadings.EnterDetails,
    element: <EnterDetails />,
  },
  [RefundRoutes.VerifyToken]: {
    key: RefundRoutes.VerifyToken,
    path: VERIFY_TOKEN_PAGE_URL,
    name: RefundPageNames.VerifyToken,
    heading: RefundPageHeadings.VerifyToken,
    element: <VerifyToken />,
  },
  [RefundRoutes.AddAccount]: {
    key: RefundRoutes.AddAccount,
    path: ADD_ACCOUNT_PAGE_URL,
    name: RefundPageNames.AddAccount,
    heading: RefundPageHeadings.AddAccount,
    element: <AddAccount />,
  },
  [RefundRoutes.Confirmation]: {
    key: RefundRoutes.Confirmation,
    path: CONFIRMATION_PAGE_URL,
    name: RefundPageNames.Confirmation,
    element: <Confirmation />,
  },
};

export const utilityRoutes: RouteInformation[] = [
  {
    key: UtilityRoutes.CallUs,
    path: UTILITY_CALL_US_PAGE_URL,
    name: UtilityPageNames.CallUs,
    element: <CallUs />,
    heading: UtilityPageHeadings.CallUs,
  },
  {
    key: UtilityRoutes.SessionTimeout,
    path: UTILITY_SESSION_TIMEOUT_PAGE_URL,
    name: UtilityPageNames.SessionTimeout,
    element: <SessionTimeout />,
    heading: UtilityPageHeadings.SessionTimeout,
  },
  {
    key: UtilityRoutes.SystemUnavailable,
    path: UTILITY_SYSTEM_UNAVAILABLE_PAGE_URL,
    name: UtilityPageNames.SystemUnavailable,
    element: <SystemUnavailable />,
    heading: UtilityPageHeadings.SystemUnavailable,
  },
];

export const refundRoutes: RouteInformation[] = Object.values(refundRoutesInfo);
export const allRoutes = [...refundRoutes, ...utilityRoutes];

export interface UseRoutesResult {
  currentStep: number;
  totalSteps: number;
  onBack: () => void;
}
